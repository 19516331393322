import React, { Component } from 'react';
import StoreBadge from 'react-store-badge';
import toast, { Toaster } from 'react-hot-toast';

import $ from 'jquery';
import axios from 'axios';

import './landing-page.css';

import LogoEscrita from '../../images/logo_escrita.png';
import Points from '../../images/points.png';
import IconeFiltro from '../../images/icone_filtro.png';
import IconeLink from '../../images/icone_link.png';
import IconeRelatorios from '../../images/icone_relatorios.png';
import IconeEmail from '../../images/email.png';
import IconePhone from '../../images/phone.png';
import LogoWhatsapp from '../../images/logo_wpp.png';
import LogoFace from '../../images/logo_fbk.png';
import LogoInsta from '../../images/logo_insta.png';
import LogoLinkedin from '../../images/logo_linkedin.png';
import LogoTwitter from '../../images/logo_twiter.png';
import AppScreen from '../../images/app_screen.png';
import TelaApp from '../../images/tela_app.png';
import Modais from '../../images/modais.png';
import IconFretado from '../../images/icon-fretado.png';
import IconFrota from '../../images/icon-frota.png';
import IconTaxi from '../../images/icon-taxi.png';
import IconViagem from '../../images/icon-viagem.png';
import AppComents from '../../images/app_coments.png';

import Modal from './more-info-modal/more-info';
import Translation from '../../components/Translation/index';
import Regions from '../../components/Regions';
import { Suspense } from 'react';
import Skeleton from 'react-loading-skeleton';

export default class LandingPage extends Component {
  constructor() {
    super();

    this.state = {
      isShowing: false,
      contrarLoading: false,
      firstnameContratar: '',
      emailContratar: '',
      phoneContratar: '',
      companyContratar: '',
      mensagemContratar: '',
      corrida_mes: '',
      funcionarios: '',
      nomeMaisInfo: '',
      emailMaisInfo: '',
      phoneMaisInfo: '',
      mensagemMaisInfo: '',
      preferencia_contato: '',
    };
  }

  openModalHandler = () => {
    document.body.classList.add('no-scroll');
    this.setState({
      isShowing: true,
      zIndex: 0,
    });
  };

  closeModalHandler = () => {
    document.body.classList.remove('no-scroll');
    this.setState({
      isShowing: false,
      zIndex: 1024,
    });
  };

  showToast = () => {
    toast.success('Enviado com sucesso.', {
      position: "bottom-center",
      duration: 4000
    });
  }

  openScroll = () => {
    const form = document.getElementById("ContratarFormLP");
    form.scrollIntoView({ behavior: 'smooth' });
  }

  componentDidMount() {
    // Smooth scrolling using jQuery easing
    // $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
    //   if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && window.location.hostname === this.hostname) {
    //     var target = $(this.hash);
    //     target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    //     if (target.length) {
    //       $('html, body').animate({
    //         scrollTop: (target.offset().top - 54)
    //       }, 1000, "easeInOutExpo");
    //       return false;
    //     }
    //   }
    // });

    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function () {
      $('.navbar-collapse').collapse('hide');
    });

    // Activate scrollspy to add active className to navbar items on scroll
    $('body').scrollspy({
      target: '#mainNav',
      offset: 56,
    });

    // Collapse Navbar
    var navbarCollapse = function () {
      if ($('#mainNav').offset().top > 80) {
        $('#mainNav').addClass('navbar-shrink');
        $('.region-selected').addClass('region-selected-white');
      } else {
        $('#mainNav').removeClass('navbar-shrink');
        $('.region-selected').removeClass('region-selected-white');
      }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);

    // adjust margim navbar
    var adjustMargim = function () {
      if ($('#mainNav').offset().top < 80) {
        $('#navb').addClass('handle-navbar-margim');
      } else {
        $('#navb').removeClass('handle-navbar-margim');
      }
    };
    // adjust margim now if page is not at top
    adjustMargim();
    // adjust margin now if navbar when page is scrolled
    $(window).scroll(adjustMargim);
  }

  handleChangeID = (object) => {
    this.setState({
      [object.target.id]: object.target.value,
    });
  };

  handleChangeName = (object) => {
    this.setState({
      [object.target.name]: object.target.value,
    });
  };

  formContratarSubmit = async (e) => {
    if (
      !(
        this.state.firstnameContratar === '' || this.state.emailContratar === ''
      )
    ) {
      e.preventDefault();

      this.setState({ contrarLoading: true });

      var res;
      var hutk = document.cookie.replace(
        /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
      );
      var currentURL = window.location.host;
      var timeStamp = Date.now().toString();

      try {
        res = await axios.post(
          'https://api.hsforms.com/submissions/v3/integration/submit/7222475/2b0f10fc-9b90-4129-979a-46ee22647e98',
          {
            submittedAt: timeStamp,
            fields: [
              {
                name: 'firstname',
                value: this.state.firstnameContratar,
              },
              {
                name: 'email',
                value: this.state.emailContratar,
              },
              {
                name: 'phone',
                value: this.state.phoneContratar,
              },
              {
                name: 'company',
                value: this.state.companyContratar,
              },
              {
                name: 'corrida_mes',
                value: this.state.corrida_mes,
              },
              {
                name: 'funcionarios',
                value: this.state.funcionarios,
              },
              {
                name: 'mensagem',
                value: this.state.mensagemContratar,
              },
            ],
            context: {
              hutk: hutk,
              pageUri: currentURL,
              pageName: 'Tripee Landing Page',
            },
            // "legalConsentOptions": { // Include this object when GDPR options are enabled
            //     "consent": {
            //         "consentToProcess": true,
            //         "text": "I agree to allow Example Company to store and process my personal data.",
            //         "communications": [
            //             {
            //                 "value": true,
            //                 "subscriptionTypeId": 999,
            //                 "text": "I agree to receive marketing communications from Example Company."
            //             }
            //         ]
            //     }
            // }
          }
        );

        if (res.status === 200) {
          document.getElementById('ContratarFormLP').submit();
        } else {
          this.setState({ err: true });
        }

        // this.showToast();
      } 
      catch (error) {
        this.setState({ err: true });
      }

      finally {
        this.showToast();
        this.setState({
          firstnameContratar: '',
          emailContratar: '',
          phoneContratar: '',
          companyContratar: '',
          mensagemContratar: '',
          corrida_mes: '',
          funcionarios: '',
          nomeMaisInfo: '',
          emailMaisInfo: '',
          phoneMaisInfo: '',
          mensagemMaisInfo: '',
          preferencia_contato: '',
        });
      }

      this.setState({ contrarLoading: false });
    }
  };

  formMaisInfoSubmit = async (e) => {
    if (!(this.state.nomeMaisInfo === '' || this.state.emailMaisInfo === '')) {
      e.preventDefault();

      var res;
      var hutk = document.cookie.replace(
        /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
      );
      var currentURL = window.location.host;
      var timeStamp = Date.now().toString();

      try {

        res = await axios.post(
          'https://api.hsforms.com/submissions/v3/integration/submit/7222475/f3c53912-de82-47e3-ab7c-881262f8a3fb',
          {
            submittedAt: timeStamp,
            fields: [
              {
                name: 'firstname',
                value: this.state.nomeMaisInfo,
              },
              {
                name: 'email',
                value: this.state.emailMaisInfo,
              },
              {
                name: 'phone',
                value: this.state.phoneMaisInfo,
              },
              {
                name: 'company',
                value: this.state.companyContratar,
              },
              {
                name: 'mensagem',
                value: this.state.mensagemMaisInfo,
              },
              {
                name: 'preferencia_contato',
                value: this.state.preferencia_contato,
              },
            ],
            context: {
              hutk: hutk,
              pageUri: currentURL,
              pageName: 'Tripee Landing Page',
            },
          }
        );

        if (res.status === 200) {
          document.getElementById('MaisInfoFormLP').submit();
        } else {
          this.setState({ err: true });
        }
      } catch (error) {
        this.setState({ err: true });
      }
    }
  };

  render() {
    return (
      <div>
        <Toaster />
        {this.state.isShowing ? (
          <div onClick={this.closeModalHandler} className='back-drop'></div>
        ) : null}

        {this.state.isShowing ? (
          <Modal
            className='modal'
            show={this.state.isShowing}
            close={this.closeModalHandler}
            changeByID={this.handleChangeID}
            changeByName={this.handleChangeName}
            submitForm={this.formMaisInfoSubmit}
          ></Modal>
        ) : null}

        <div id='pageTop'>
          <nav
            id='mainNav'
            className='navbar navbar-expand-lg navbar-dark justify-content-end fixed-top col-xs-12'
            style={{ zIndex: this.state.zIndex }}
          >
            <div className='container-fluid'>
              <a className='navbar-brand js-scroll-trigger' href='#pageTop'>
                <img id='logo' src={LogoEscrita} alt='Tripee' align='left' />
              </a>

              <button
                className='navbar-toggler navbar-toggler-right ml-auto '
                type='button'
                data-toggle='collapse'
                data-target='#navb'
              >
                <span className='navbar-toggler-icon'></span>
              </button>

              <div className='collapse navbar-collapse' id='navb'>
                <ul className='navbar-nav ml-auto mr-1'>
                  <li className='nav-item'>
                    <a className='nav-link js-scroll-trigger' href='#sobre'>
                      <Translation translationKey='navigation.abount' />
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link js-scroll-trigger' href='#contratar'>
                      <Translation translationKey='navigation.more' />
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link js-scroll-trigger' href='#contatos'>
                      <Translation translationKey='navigation.contact' />
                    </a>
                  </li>
                    <Suspense fallback={<Skeleton count={1} />}>
                      <Regions title="Brazil" country='BR' />
                    </Suspense>
                </ul>
              </div>
            </div>
          </nav>

          <header className='masthead' id='home'>
            <div className='intro-text'>
              <p className='col-sm-7 col-md-8 col-lg-8 centraliza home-titulo'>
                <Translation  translationKey="home-title" />
              </p>
              <div className='col-sm-7 col-md-8 col-lg-8 centraliza'>
                <p className='col-sm-11 col-md-8 col-lg-7 home-subtitulo'>
                  <Translation translationKey="home-subtitle" />
                </p>
              </div>
              <p className='col-sm-7 col-md-8 col-lg-8 centraliza'>
                <img id='points' src={Points} alt='Points' />
              </p>
              <div className='col-sm-7 col-md-8 col-lg-8 centraliza'>
                <p className='col-sm-11 col-md-8 col-lg-7 home-context'>
                  <Translation translationKey="description" />
                </p>
              </div>
              <div className='col-sm-7 col-md-8 col-lg-8 centraliza'>
                <button
                  className='btn-shape btn-quero-saber blue'
                  onClick={this.openScroll}
                >
                   <Translation translationKey="button.more" />
                </button>
              </div>
            </div>
          </header>

          <section className='bg-light page-section' id='sobre'>
            <div>
              <div
                className='col-sm col-md col-lg'
                style={{ color: '#4588e5', fontSize: 18, fontWeight: 'bold' }}
              >
                <Translation translationKey="abount.title" />
              </div>
              <div
                className='row'
                style={{
                  borderStyle: 'solid',
                  borderRadius: '25px',
                  borderColor: '#4588e5',
                }}
              >
                <div id='div-modal-icon' className='col-sm-6 col-md-6 col-lg'>
                  <img id='modal-icon' src={IconFretado} alt='Modal Icon' />
                  <p className='modal-title'>
                    <Translation translationKey="abount.modals.ride.title" />
                  </p>
                  <div>
                    <Translation translationKey="abount.modals.ride.description" />
                  </div>
                </div>

                <div id='div-modal-icon' className='col-sm-6 col-md-6 col-lg'>
                  <img id='modal-icon' src={IconFrota} alt='Modal Icon' />
                  <p className='modal-title'>
                    <Translation translationKey="abount.modals.chartered.title" />
                  </p>
                  <div>
                    <Translation translationKey="abount.modals.chartered.description" />
                  </div>
                </div>

                <div id='div-modal-icon' className='col-sm-6 col-md-6 col-lg'>
                  <img id='modal-icon' src={IconTaxi} alt='Modal Icon' />
                  <p className='modal-title'>
                    <Translation translationKey="abount.modals.fleet.title" />
                  </p>
                  <div>
                    <Translation translationKey="abount.modals.fleet.description" />
                  </div>
                </div>

                <div id='div-modal-icon' className='col-sm-6 col-md-6 col-lg'>
                  <img id='modal-icon' src={IconViagem} alt='Modal Icon' />
                  <p className='modal-title'>
                    <Translation translationKey="abount.modals.airplane-trip.title" />
                  </p>
                  <div>
                    <Translation translationKey="abount.modals.airplane-trip.description" />
                  </div>
                </div>
              </div>

              <div className='container-fluid'>
                <div className='row'>
                  {/* <div className='col-sm-0 col-md-0 col-lg-0-5'></div> */}
                  <div className='col-sm-12 col-md-12 col-lg-4 text-left'>
                    <img id='icone-filtro' src={IconeFiltro} alt='Filtro' />
                    <p className='sobre-title'>
                      <Translation translationKey="abount.informations.info1.title" />
                    </p>
                    <p className='sobre-context'>
                      <Translation translationKey="abount.informations.info1.description" />
                    </p>
                  </div>

                  {/* <div className='col-sm-0 col-md-0 col-lg-1'></div> */}
                  <div className='col-sm-12 col-md-12 col-lg-4 text-left'>
                    <img
                      id='icone-relatorios'
                      src={IconeRelatorios}
                      alt='Relatorios'
                    />
                    <p className='sobre-title'>
                      <Translation translationKey="abount.informations.info2.title" />
                    </p>
                    <p className='sobre-context'>
                      <Translation translationKey="abount.informations.info2.description" />
                    </p>
                  </div>

                  {/* <div className='col-sm-0 col-md-0 col-lg-1'></div> */}
                  <div className='col-sm-12 col-md-12 col-lg-4 text-left'>
                    <img id='icone-link' src={IconeLink} alt='Link' />
                    <p className='sobre-title'>
                      <Translation translationKey="abount.informations.info3.title" />
                    </p>
                    <p className='sobre-context'>
                      <Translation translationKey="abount.informations.info3.description" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='page-section' id='contratar'>
            <form id='ContratarFormLP'>
              <div className='row'>
                <div className='col-lg-6 contratar text-left'>
                  <p className='contratar-titulo'>
                    <Translation translationKey="form.title" />
                  </p>
                  <p className='contratar-context'>
                    <Translation translationKey="form.description" />
                  </p>

                  <div className='col-lg-8'>
                    <div className='label-float'>
                      <input
                        id='firstnameContratar'
                        name='firstname'
                        value={this.state.firstnameContratar}
                        onChange={(object) => this.handleChangeID(object)}
                        type='text'
                        placeholder=' '
                        required
                      />
                      {/* <input id="txtContratarNome" name="txtContratarNome" value={this.state.txtContratarNome} onChange={object => this.handleChange(object)} type="text" placeholder=" " required /> */}
                      <label>
                        <Translation translationKey="form.label.name" />
                      </label>
                    </div>
                    <div className='label-float'>
                      <input
                        id='emailContratar'
                        name='email'
                        value={this.state.emailContratar}
                        onChange={(object) => this.handleChangeID(object)}
                        type='text'
                        placeholder=' '
                        required
                      />
                      {/* <input id="txtContratarEmail" name="txtContratarEmail" value={this.state.txtContratarEmail} onChange={object => this.handleChange(object)} type="text" placeholder=" " required /> */}
                      <label>
                        <Translation translationKey="form.label.email" />
                      </label>
                    </div>
                    <div className='label-float'>
                      <input
                        id='phoneContratar'
                        name='phone'
                        value={this.state.phoneContratar}
                        onChange={(object) => this.handleChangeID(object)}
                        type='text'
                        placeholder=' '
                      />
                      {/* <input id="txtContratarTelefone" name="txtContratarTelefone" value={this.state.txtContratarTelefone} onChange={object => this.handleChange(object)} type="text" placeholder=" " required /> */}
                      <label>
                        <Translation translationKey="form.label.cellphone" />
                      </label>
                    </div>
                    <div className='label-float'>
                      <input
                        id='companyContratar'
                        name='company'
                        value={this.state.companyContratar}
                        onChange={(object) => this.handleChangeID(object)}
                        type='text'
                        placeholder=' '
                      />
                      {/* <input id="txtContratarEmpresa" name="txtContratarEmpresa" value={this.state.txtContratarEmpresa} onChange={object => this.handleChange(object)} type="text" placeholder=" " /> */}
                      <label>
                        <Translation translationKey="form.label.company" />
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-4 corrida-mes'>
                    <div className='col-lg-12 col-xs-12 text-left'>
                      <p className=''>
                        <Translation translationKey="form.races-per-month" />
                      </p>
                      <div>
                        <label className='custom-radio-button'>
                          <input
                            type='radio'
                            id='corridaMes1'
                            name='corrida_mes'
                            value='1 - 500'
                            onChange={(object) => this.handleChangeName(object)}
                          />
                          {/* <input type="radio" id="corridaMes1" name="corrida_mes" value="1 - 200" onChange={object => this.handleChange(object)} /> */}
                          <span className='helping-el'></span>
                          <span className='label-text'>1 - 500</span>
                        </label>
                      </div>
                      <div>
                        <label className='custom-radio-button'>
                          <input
                            type='radio'
                            id='corridaMes2'
                            name='corrida_mes'
                            value='501 - 1.000'
                            onChange={(object) => this.handleChangeName(object)}
                          />
                          {/* <input type="radio" id="corridaMes2" name="corrida_mes" value="200 - 1000" onChange={object => this.handleChange(object)} /> */}
                          <span className='helping-el'></span>
                          <span className='label-text'>501 - 1.000</span>
                        </label>
                      </div>
                      <div>
                        <label className='custom-radio-button'>
                          <input
                            type='radio'
                            id='corridaMes3'
                            name='corrida_mes'
                            value='1.001+'
                            onChange={(object) => this.handleChangeName(object)}
                          />
                          {/* <input type="radio" id="corridaMes3" name="corrida_mes" value="1000 +" onChange={object => this.handleChange(object)} /> */}
                          <span className='helping-el'></span>
                          <span className='label-text'>1.001+</span>
                        </label>
                      </div>
                    </div>
                    <div className='col-lg-12 text-left'>
                      <p>
                        <Translation translationKey="form.employees" />
                      </p>
                      <div>
                        <label className='custom-radio-button'>
                          <input
                            id='funcionarios1'
                            type='radio'
                            name='funcionarios'
                            value='1 - 500'
                            onChange={(object) => this.handleChangeName(object)}
                          />
                          {/* <input id="funcionarios1" type="radio" name="funcionarios" value="1 - 50" onChange={object => this.handleChange(object)} /> */}
                          <span className='helping-el'></span>
                          <span className='label-text'>1 - 500</span>
                        </label>
                      </div>
                      <div>
                        <label className='custom-radio-button'>
                          <input
                            id='funcionarios2'
                            type='radio'
                            name='funcionarios'
                            value='501 - 1.000'
                            onChange={(object) => this.handleChangeName(object)}
                          />
                          {/* <input id="funcionarios2" type="radio" name="funcionarios" value="50 - 500" onChange={object => this.handleChange(object)} /> */}
                          <span className='helping-el'></span>
                          <span className='label-text'>501 - 1.000</span>
                        </label>
                      </div>
                      <div>
                        <label className='custom-radio-button'>
                          <input
                            id='funcionarios3'
                            type='radio'
                            name='funcionarios'
                            value='1.001+'
                            onChange={(object) => this.handleChangeName(object)}
                          />
                          {/* <input id="funcionarios3" type="radio" name="funcionarios" value="500 +" onChange={object => this.handleChange(object)} /> */}
                          <span className='helping-el'></span>
                          <span className='label-text'>1.001+</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='label-float'>
                      <textarea
                        id='mensagemContratar'
                        name='mensagem'
                        value={this.state.mensagemContratar}
                        onChange={(object) => this.handleChangeID(object)}
                        rows='10'
                      />
                      {/* <textarea id="mensagem" name="mensagem" value={this.state.txtContratarMensagem} onChange={object => this.handleChange(object)} rows="10" required> */}
                      <label>
                        <Translation translationKey="form.textarea" />
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-12 text-right'>
                    <button
                      type='submit'
                      className='btn-shape btn-enviar-1 green-light'
                      onClick={(e) => this.formContratarSubmit(e)}
                    >
                      {this.state.contrarLoading ? 'Loading' : <Translation translationKey="form.submit" />}
                    </button>
                  </div>
                </div>
                <div className='col-lg-6 app-print'>
                  <div className='col-sm-12 col-md-12 col-lg-12 app-screen'>
                    <img id='app-screen' src={TelaApp} alt='Link' />
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <img id='app-coments' src={AppComents} alt='Link' />
                  </div>
                </div>
              </div>
            </form>
          </section>

          <section className='page-section' id='contatos'>
            <div className='container-fluid'>
              {/* <div className="d-flex justify-content-center"> */}
              <div className='row'>
                <div className='col-sm-0 col-lg-1'></div>

                <div className='col-sm-12 col-md-3 col-lg-3 text-left contatos'>
                  <p className='contatos-titulo'>
                    <Translation translationKey="footer.column1" />
                  </p>
                  <div className='row row-email'>
                    <img id='icone-email' src={IconeEmail} alt='Link' />
                    <span className='contatos-context'>contato@tripee.to</span>
                  </div>
                  {/* <div className="row">
                    <img id="icone-phone" src={IconePhone} alt="Link" />
                    <span className="contatos-context">
                      +55 (011) 5200-0207
                    </span>
                  </div> */}
                </div>

                <div className='col-sm-0 col-lg-0-5'></div>

                <div className='col-sm-12 col-md-3 col-lg-3 text-left rede-social'>
                  <p className='contatos-titulo'>
                    <Translation translationKey="footer.column2" />
                  </p>
                  <div className='row'>
                    <a href='https://www.facebook.com/tripee.mobilidade'>
                      <img id='icone-face' src={LogoFace} alt='Link' />
                    </a>
                    <a href='https://www.instagram.com/tripee.mobilidade'>
                      <img id='icone-insta' src={LogoInsta} alt='Link' />
                    </a>
                    <a href='https://www.linkedin.com/company/tripeemobilidade'>
                      <img id='icone-linkedin' src={LogoLinkedin} alt='Link' />
                    </a>

                    <a href='https://wa.me/5511993300360'>
                      <img id='icone-whatsapp' src={LogoWhatsapp} alt='Link' />
                    </a>

                    {/* <a href="https://twitter.com/Tripee_to/">
                      <img id="icone-twitter" src={LogoTwitter} alt="Link" />
                    </a> */}
                  </div>
                </div>

                <div className='col-sm-0 col-lg-0-5'></div>

                <div className='col-sm-12 col-md-3 col-lg-4 text-left'>
                  <p className='contatos-titulo'>
                    <Translation translationKey="footer.column3" />
                  </p>
                  <div className='badge'>
                    <StoreBadge
                      name='Tripee'
                      googlePlayUrl='https://play.google.com/store/apps/details?id=to.tripee.tripeeapp'
                      appStoreUrl='https://apps.apple.com/br/app/tripee/id1502856489?mt=8'
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>

          <footer>
            <div className='container-fluid'>
              <div className='d-flex justify-content-center'>
                <div className='col-lg-3 text-left'>
                  <span className='copyright'>
                    Copyright 2023 | Designed by Tripee®
                  </span>
                </div>
                <div className='col-lg-8'></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
