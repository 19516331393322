import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(backend)
  // .use(resourcesToBackend((language, namespace) => import(`../public/locales/${language}/${namespace}.json`)))
  .init({
    debug: false,
    load: 'all',
    detection: {
      order: ['localStorage', 'sessionStorage', 'navigator', 'cookie'],
      caches: ['localStorage', 'cookie'],
      checkWhitelist: true,
      whitelist: ['en-US', 'pt-BR'],
      regex: /^[a-z]{2}-[A-Z]{2}$/
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: './locales/{{lng}}/translation.json'
    },
    // load: 'l',
     // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: "en-US", // standard
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;