import "./styles.css";
import React from "react";
import { BR, US  } from 'country-flag-icons/react/3x2';
import { withTranslation } from "react-i18next";

function Regions({ i18n, t  }) {
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <div className="region-flex">
            <div 
                className={`region-column${t('flag') === 'en-US' ? ' region-selected' : ''}`} 
                role="button"
                onClick={() => changeLanguage('en-US')}
            >
                <US title='United States' className="country_" />
                <strong translate="no">en-US</strong>
            </div>
            <div 
                className={`region-column${t('flag') === 'pt-BR' ? ' region-selected' : ''}`} 
                role="button"
                onClick={() => changeLanguage('pt-BR')}
            >
                <BR title='Brasil' className="country_"/>
                <strong translate="no">pt-BR</strong>
            </div>
        </div>
    );
}

export default withTranslation()(Regions);