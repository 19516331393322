import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LandingPage from './pages/landing-page/landing-page';
import AutoConvite from './pages/auto-convite/index';
import PrivacyPolicy from './pages/privacy-policy/index';
import UsePolicy from './pages/use-policy';

function Routes() {
  return (
    <Switch>
      <Route exact path='/' component={LandingPage} />
      <Route exact path='/home' component={LandingPage} />
      <Route exact path='/techdata/convite' component={AutoConvite} />
      <Route exact path='/policy/privacy' component={PrivacyPolicy} />
      <Route exact path='/policy/use' component={UsePolicy} />
    </Switch>
  );
}

export default Routes;
