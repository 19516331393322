import React, { Component } from 'react';

export default class UsePolicy extends Component {
  render() {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '25px 0' }}
      >
        <div style={{ width: '75%', textAlign: 'justify' }}>
          <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Termos Gerais de Uso Tripee
          </p>
          <p>
            POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS
            SERVIÇOS.
          </p>
          <p>
            Ao acessar e usar os Serviços você concorda com os presentes termos
            e condições, que estabelecem o relacionamento contratual entre você,
            na qualidade de usuário(a), e a Tripee. Se você não concorda com
            estes Termos, você não pode acessar nem usar os Serviços. Mediante
            referido acesso e uso, estes Termos imediatamente encerram,
            substituem e superam todos os acordos, Termos e acertos anteriores
            entre você e a Tripee.
          </p>
          <p>
            Termos adicionais poderão se aplicar a determinados Serviços, tais
            como condições para um evento, atividade ou promoção em particular,
            e esses termos adicionais serão divulgados em relação aos
            respectivos Serviços.
          </p>
          <p>
            A Tripee poderá alterar os Termos relativos aos Serviços a qualquer
            momento. Aditamentos entrarão em vigor quando a Tripee fizer a
            postagem da versão atualizada dos Termos neste local ou das
            condições atualizadas ou Termos adicionais sobre o respectivo
            Serviço. O fato de você continuar a acessar ou usar os Serviços após
            essa postagem representa seu consentimento em vincular-se aos Termos
            alterados.
          </p>
          <p style={{ fontSize: '16px', fontWeight: 'bold' }}>I. Os Serviços</p>
          <p>
            Os Serviços integram uma plataforma de tecnologia que permite
            aos(às) usuários(as) de aplicativos móveis ou sites de Internet da
            Tripee, fornecidos como parte dos Serviços (cada qual um
            “Aplicativo”), solicitar e programar serviços de transporte. VOCÊ
            RECONHECE QUE A Tripee NÃO É FORNECEDORA DE BENS, NÃO PRESTA
            SERVIÇOS DE TRANSPORTE OU LOGÍSTICA, NEM FUNCIONA COMO
            TRANSPORTADORA, E QUE TODOS ESSES SERVIÇOS DE TRANSPORTE SÃO
            PRESTADOS POR FORNECEDORES, QUE NÃO SÃO REPRESENTANTES DA TRIPEE.
          </p>
          <p style={{ fontWeight: 'bold' }}>1. Licença</p>
          <p>
            Sujeito ao cumprimento destes Termos, a Tripee outorga a você uma
            licença limitada, não exclusiva, não passível de sublicença,
            revogável e não transferível para: (i) acesso e uso dos Aplicativos
            em seu dispositivo pessoal, exclusivamente para o seu uso dos
            Serviços; e (ii) acesso e uso de qualquer conteúdo, informação e
            material correlato que possa ser disponibilizado por meio dos
            Serviços, em cada caso, para seu uso pessoal, nunca comercial.
            Quaisquer direitos não expressamente outorgados por estes Termos são
            reservados à Tripee.
          </p>
          <p style={{ fontWeight: 'bold' }}>2. Restrições</p>
          <p>
            Você não poderá: (i) remover qualquer aviso de direito autoral,
            direito de marca ou outro aviso de direito de propriedade de
            qualquer parte dos Serviços; (ii) reproduzir, modificar, distribuir,
            licenciar, alugar, vender, revender, transferir, exibir, veicular,
            transmitir ou, de qualquer outro modo, explorar os Serviços, exceto
            da forma expressamente permitida pela Tripee; (iii) decompilar,
            realizar engenharia reversa ou desmontar os Serviços, exceto
            conforme permitido pela legislação aplicável; (iv) conectar,
            espelhar ou recortar qualquer parte dos Serviços; (v) fazer ou
            lançar quaisquer programas ou scripts com a finalidade de
            sobrecarregar ou prejudicar indevidamente a operação e/ou
            funcionalidade de qualquer aspecto dos Serviços; ou (vi) tentar
            obter acesso não autorizado aos Serviços ou prejudicar qualquer
            aspecto dos Serviços ou seus sistemas ou redes correlatas.
          </p>
          <p style={{ fontWeight: 'bold' }}>3. Prestação dos Serviços</p>
          <p>
            Você reconhece que os Serviços são disponibilizados apenas pela
            plataforma Tripee contendo diferentes fornecedores parceiros e que
            há diferentes opções de solicitação aos serviços de transporte
            prestados pelos fornecedores da Tripee.
          </p>
          <p>
            Os Serviços poderão ser disponibilizados e acessados em conexão com
            Serviços e conteúdo de terceiros(as) que a Tripee não controlará.
            VOCÊ RECONHECE QUE TERMOS DE USO E POLÍTICAS DE PRIVACIDADE
            DIFERENTES PODERÃO SER APLICÁVEIS AO USO DESSES SERVIÇOS E CONTEÚDO
            DE TERCEIROS(AS). A TRIPEE NÃO SERÁ, EM HIPÓTESE ALGUMA, RESPONSÁVEL
            POR NENHUM PRODUTO OU SERVIÇO DESSES(AS) TERCEIROS(AS)
            FORNECEDORES(AS).
          </p>
          <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
            II. Uso dos Serviços
          </p>
          <p style={{ fontWeight: 'bold' }}>1. Contas de Usuários</p>
          <p>
            Para utilizar grande parte dos Serviços, você deve registrar-se e
            manter uma conta corporativa de usuário de Serviços (“Conta”). Você
            deve ter pelo menos 16 anos para registrar uma Conta. O registro de
            Conta exige que a Tripee colete determinados dados pessoais, que
            podem incluir seu nome e número de telefone celular. Você concorda
            em manter informações corretas, completas e atualizadas em sua
            Conta. Se você não mantiver informações corretas, completas e
            atualizadas em sua Conta, você poderá ficar impossibilitado(a) de
            acessar e usar os Serviços ou a Tripee poderá resolver estes Termos.
            Você é responsável por todas as atividades realizadas na sua Conta e
            concorda em manter sempre a segurança e confidencialidade do nome de
            usuário e senha da sua Conta.
          </p>
          <p style={{ fontWeight: 'bold' }}>2. Mensagem de Texto (SMS)</p>
          <p>
            Ao criar uma Conta, e tal como estabelecido na Política de
            Privacidade, você concorda que os Serviços poderão lhe enviar
            mensagens de textos (SMS) como parte das operações regulares para o
            uso dos Serviços.
          </p>
          <p style={{ fontWeight: 'bold' }}>3. Pagamento</p>
          <p>
            Você entende que o preço dos serviços deve ser pago imediatamente
            após a prestação do serviço pelos motoristas parceiros dos
            fornecedores da Tripee e que o pagamento será realizado pela empresa
            na qual você trabalha.
          </p>
          <p>
            O preço pago no final da viagem não é reembolsável, a menos que seja
            determinado pelos fornecedores da Tripee.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            4. Conteúdo Fornecido pelo(a) Usuário(a).
          </p>
          <p>
            A Tripee poderá, a seu exclusivo critério, permitir que você ou
            qualquer pessoa apresente, carregue, publique ou, de qualquer modo,
            disponibilize para a Tripee por meio dos Serviços, conteúdo e
            informações de texto, áudio ou vídeo, inclusive comentários e
            feedbacks relacionados aos Serviços, iniciação de solicitação de
            suporte e registro em concursos e promoções. Qualquer Conteúdo de
            Usuário(a) fornecido por você permanece de sua propriedade. Contudo,
            ao fornecer Conteúdo de Usuário(a) para a Tripee, você outorga a
            Tripee uma licença dos direitos autorais sobre o Conteúdo de Usuário
            em nível mundial, perpétua, irrevogável, transferível, isenta de
            royalties, e com direito a sublicenciar, usar, copiar, modificar,
            criar obras derivadas, distribuir, publicar, exibir, executar em
            público e, de qualquer outro modo, explorar esse Conteúdo de
            Usuário(a) em todos os formatos e canais de distribuição hoje
            conhecidos ou desenvolvidos no futuro (inclusive em conexão com os
            Serviços e com os negócios da Tripee e em sites e Serviços de
            terceiros), sem ulterior aviso a você ou seu consentimento, e sem
            necessidade de pagamento a você ou a qualquer outra pessoa ou
            entidade.
          </p>
          <p>
            Você concorda em não fornecer Conteúdo de Usuário(a) que seja
            difamatório, calunioso, injurioso, violento, obsceno, pornográfico,
            ilegal ou de qualquer modo ofensivo, conforme apuração da Tripee a
            seu critério exclusivo, seja ou não esse material protegido por lei.
            A Tripee poderá analisar, monitorar ou remover Conteúdo de
            Usuário(a), a critério exclusivo da Tripee, a qualquer momento e por
            qualquer motivo, sem nenhum aviso a você.
          </p>
          <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
            III. Conduta e Obrigações do Usuário
          </p>
          <p>
            Você não poderá autorizar terceiros(as) a usar sua Conta, salvo se
            estiverem em sua companhia. Você não poderá ceder, nem de qualquer
            outro modo transferir, sua Conta a nenhuma outra pessoa ou entidade.
            Você concorda em cumprir todas as leis aplicáveis quando usar os
            Serviços e que somente poderá usar os Serviços para finalidades
            corporativas e legítimas (por ex. não transportar materiais ilegais
            ou perigosos). Você não poderá, quando usar os Serviços, causar
            transtorno, aborrecimento, inconveniente ou danos à propriedade dos
            motoristas parceiros ou de qualquer outro terceiro.
          </p>
          <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
            IV. Limitação de Responsabilidade
          </p>
          <p style={{ fontWeight: 'bold' }}>1. Recusa de Garantia</p>
          <p>
            OS SERVIÇOS SÃO PRESTADOS “NO ESTADO” E “COMO DISPONÍVEIS”. A TRIPEE
            RECUSA TODAS AS DECLARAÇÕES E GARANTIAS, EXPRESSAS, IMPLÍCITAS OU
            LEGAIS, NÃO EXPRESSAMENTE CONTIDAS NESTES TERMOS, INCLUSIVE AS
            GARANTIAS IMPLÍCITAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UMA FINALIDADE
            ESPECÍFICA E NÃO INFRINGÊNCIA. ADEMAIS, A TRIPEE NÃO FAZ NENHUMA
            DECLARAÇÃO NEM DÁ GARANTIA SOBRE A CONFIABILIDADE, PONTUALIDADE,
            QUALIDADE, ADEQUAÇÃO OU DISPONIBILIDADE DOS SERVIÇOS OU DE QUAISQUER
            SERVIÇOS OU BENS SOLICITADOS POR MEIO DO USO DOS SERVIÇOS, NEM QUE
            OS SERVIÇOS SERÃO ININTERRUPTOS OU LIVRES DE ERROS. A TRIPEE NÃO
            GARANTE A QUALIDADE, ADEQUAÇÃO OU SEGURANÇA DAS VIAGENS POR
            FORNECEDORES PARCEIROS. VOCÊ CONCORDA QUE TODO O RISCO DECORRENTE DO
            USO DOS SERVIÇOS E DE QUALQUER SERVIÇO OU BEM SOLICITADO POR MEIO DA
            TECNOLOGIA SERÁ SEMPRE SEU OU DO FORNECEDOR DA TRIPEE NA MÁXIMA
            MEDIDA PERMITIDA PELA LEI APLICÁVEL.
          </p>
          <p style={{ fontWeight: 'bold' }}>2. Limitação de Responsabilidade</p>
          <p>
            A TRIPEE NÃO SERÁ RESPONSÁVEL POR DANOS INDIRETOS, INCIDENTAIS,
            ESPECIAIS, PUNITIVOS OU EMERGENTES, INCLUSIVE LUCROS CESSANTES,
            PERDA DE DADOS, DANOS MORAIS OU PATRIMONIAIS RELACIONADOS,
            ASSOCIADOS OU DECORRENTES DE QUALQUER USO DOS SERVIÇOS AINDA QUE A
            TRIPEE TENHA SIDO ALERTADA PARA A POSSIBILIDADE DESSES DANOS. A
            TRIPEE NÃO SERÁ RESPONSÁVEL POR NENHUM DANO, OBRIGAÇÃO OU PREJUÍZO
            DECORRENTE DO: (i) SEU USO DOS SERVIÇOS OU SUA INCAPACIDADE DE
            ACESSAR OU USAR OS SERVIÇOS; OU (ii) QUALQUER OPERAÇÃO OU
            RELACIONAMENTO ENTRE VOCÊ E QUALQUER FORNECEDOR, AINDA QUE A TRIPEE
            TENHA SIDO ALERTADA PARA A POSSIBILIDADE DESSES DANOS. A TRIPEE NÃO
            SERÁ RESPONSÁVEL POR ATRASOS OU FALHAS DECORRENTES DE CAUSAS FORA DO
            CONTROLE RAZOÁVEL DA TRIPEE E, TAMPOUCO, PELA QUALIDADE E
            INTEGRIDADE DOS BENS DISPONIBILIZADOS POR PARCEIROS.
          </p>
          <p>
            AS LIMITAÇÕES E RECUSA DE GARANTIAS CONTIDAS NESTA CLÁUSULA NÃO
            POSSUEM O OBJETIVO DE LIMITAR RESPONSABILIDADES OU ALTERAR DIREITOS
            DE CONSUMIDOR QUE, DE ACORDO COM A LEI APLICÁVEL, NÃO PODEM SER
            LIMITADOS OU ALTERADOS.
          </p>
          <p style={{ fontWeight: 'bold' }}>3. Seguro de Acidentes Pessoais</p>
          <p>
            VOCÊ RECONHECE QUE A TRIPEE PODE DISPONIBILIZAR SEGURO DE ACIDENTES
            PESSOAIS DE PASSAGEIROS, COM COBERTURA NOS TERMOS E LIMITES DA
            APÓLICE, SE FOREM CONTRATADOS PELA EMPRESA CLIENTE.
          </p>
          <p style={{ fontWeight: 'bold' }}>4. Solução de controvérsias</p>
          <p>
            A Tripee disponibiliza uma estrutura de atendimento, composta por
            ferramentas de contato e uma equipe especializada de suporte para o
            tratamento de reclamações, com o objetivo de evitar a judicialização
            de controvérsias que, eventualmente, possam surgir com o seu uso dos
            Serviços.
          </p>
          <p>
            Diante disso, você se compromete a utilizar as ferramentas de
            suporte disponibilizadas pela Tripee como primeiro meio para a
            solução de controvérsias decorrentes do seu uso dos Serviços.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            5. Legislação Aplicável; Jurisdição
          </p>
          <p>
            Estes Termos serão regidos e interpretados exclusivamente de acordo
            com as leis do Brasil. Qualquer reclamação, conflito ou controvérsia
            que surgir deste contrato ou a ele relacionada, inclusive que diga
            respeito a sua validade, interpretação ou exequibilidade, será
            solucionada exclusivamente pelos tribunais do foro de seu domicílio.
          </p>
          <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
            V. Outras Disposições
          </p>
          <p style={{ fontWeight: 'bold' }}>
            1. Alegações de Violação de Direito Autoral
          </p>
          <p>
            Alegações de violação de direito autoral devem ser encaminhadas ao
            representante indicado pela Tripee.
          </p>
          <p style={{ fontWeight: 'bold' }}>2. Avisos</p>
          <p>
            A Tripee poderá enviar avisos por meio de notificações gerais nos
            Serviços. Você poderá notificar a Tripee por meio do Aplicativo ou
            comunicação pelo e-mail suporte@tripee.to
          </p>
          <p style={{ fontWeight: 'bold' }}>3. Disposições Gerais</p>
          <p>
            Caso qualquer disposição destes Termos seja tida como ilegal,
            inválida ou inexequível total ou parcialmente, por qualquer
            legislação, essa disposição ou parte dela será, naquela medida,
            considerada como não existente para os efeitos destes Termos, mas a
            legalidade, validade e exequibilidade das demais disposições
            contidas nestes Termos não serão afetadas. Nesse caso, as partes
            substituirão a disposição ilegal, inválida ou inexequível, ou parte
            dela, por outra que seja legal, válida e exequível e que, na máxima
            medida possível, tenha efeito similar à disposição tida como ilegal,
            inválida ou inexequível para fins de conteúdo e finalidade dos
            presentes Termos. Estes Termos constituem a totalidade do acordo e
            entendimento das partes sobre este assunto e substituem e prevalecem
            sobre todos os entendimentos e compromissos anteriores sobre este
            assunto.
          </p>
        </div>
      </div>
    );
  }
}
