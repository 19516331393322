import React from "react";

import "./more-info.css";

const modal = (props) => {
  return (
    <div>
      <div
        className="modal-wrapper"
        style={{
          transform: props.show ? "translateX(0vw)" : "translateX(100vw)",
          opacity: props.show ? "1" : "0",
        }}
      >
        <form id="MaisInfoFormLP">
          <div className="row">
            <div className="col-lg-12 mais-info text-left">
              <p className="mais-info-titulo">QUER MAIS INFORMAÇÕES?</p>
              <p className="mais-info-context">
                Envie sua mensagem e entraremos em contato em até 24 horas.
              </p>

              <div className="row mais-info-input">
                <div className="col-lg-12 adjust-mobile">
                  <div className="col-lg-12">
                    <div className="label-float label-float-modal">
                      <input
                        id="nomeMaisInfo"
                        name="firstname"
                        onChange={(object) => props.changeByID(object)}
                        type="text"
                        placeholder=" "
                        required
                      />
                      <label>Nome</label>
                    </div>
                    <div className="label-float label-float-modal">
                      <input
                        id="emailMaisInfo"
                        name="email"
                        onChange={(object) => props.changeByID(object)}
                        type="text"
                        placeholder=" "
                        required
                      />
                      <div>
                        <label>Email</label>
                      </div>
                    </div>
                    <div className="label-float label-float-modal">
                      <input
                        id="phoneMaisInfo"
                        name="phone"
                        onChange={(object) => props.changeByID(object)}
                        type="text"
                        placeholder=" "
                      />
                      <label>Telefone</label>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="label-float label-float-modal">
                      <textarea
                        id="mensagemMaisInfo"
                        name="mensagem"
                        onChange={(object) => props.changeByID(object)}
                        rows="6"
                      />
                      <label>Mensagem</label>
                    </div>
                  </div>

                  <div className="col-lg-12 text-left radio-btn">
                    <p>Como prefere falar com a Tripee?</p>
                    <div className="row">
                      <div>
                        <label className="custom-radio-button">
                          <input
                            id="pref1"
                            type="radio"
                            name="preferencia_contato"
                            value="Email"
                            onChange={(object) => props.changeByName(object)}
                          />
                          <span className="helping-el"></span>
                          <span className="label-text">Email</span>
                        </label>
                      </div>
                      <div>
                        <label className="custom-radio-button">
                          <input
                            id="pref2"
                            type="radio"
                            name="preferencia_contato"
                            value="Telefone"
                            onChange={(object) => props.changeByName(object)}
                          />
                          <span className="helping-el"></span>
                          <span className="label-text">Telefone</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 text-right">
                    <button
                      className="btn-shape btn-enviar-1 green-light"
                      onClick={(e) => props.submitForm(e)}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default modal;
