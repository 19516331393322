import React, { Component } from 'react';

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '25px 0' }}
      >
        <div style={{ width: '75%', textAlign: 'justify' }}>
          <div>
            <h1>
              <p>
                <span>Política de Privacidade Tripee</span>
              </p>
            </h1>
          </div>

          <h5>
            <p>
              <span>
                1. Quais dados coletamos sobre você e para qual finalidade?
              </span>
            </p>
          </h5>
          <p>
            Coletamos dados de diferentes tipos de Titulares de Dados (Pessoas
            Físicas) que podem ser:
          </p>
          <p>
            • Usuários da Plataforma Tripee.
            <br />• Empregados da Tripee.
          </p>
          <p>Para os titulares de dados do tipo </p>
          <p>
            Empregados da Tripee, realizamos a coleta de seus dados pessoais
            para efetivação de sua contratação e cumprimento das leis
            trabalhistas e fiscais vigentes no Brasil.
          </p>
          <p>
            <span>Para os titulares de dados do tipo </span>
            <span>
              Usuários nossa plataforma coleta e utiliza alguns dados pessoais
              seus, de forma a viabilizar a prestação de serviços e aprimorar a
              experiência de uso.
            </span>
          </p>
          <p>
            <span>
              A primeira forma de coleta ocorre com a recepção de dados pessoais
              dos Usuários, enviados no momento do cadastro realizado pela
              empresa Cliente de seus colaboradores e funcionários como usuários
              dentro da Plataforma Tripee.
            </span>
          </p>
          <p>
            <span>Este cadastro pode ser para a utilização do produto de</span>
            <span>Mobilidade</span>
            <span>, </span>
            <span>Viagens</span>
            <span>
              &nbsp;ou ambos. Para cada tipo de produto podemos coletar dados
              pessoais distintos, coletando mais ou menos informações.
            </span>
          </p>
          <p>
            <span>
              Ao realizar o cadastro em nosso sistema, conforme o produto
              apropriado, você pode ser solicitado a digitar seu nome, endereço
              de e-mail, número de telefone ou outros detalhes para ajudá-lo com
              sua experiência.
            </span>
          </p>
          <p>
            <span>Se seu produto for </span>
            <span>Viagens</span>
            <span>
              &nbsp;iremos coletar dados de identificação pessoal como CPF ou
              Passaporte para podermos cumprir com a regra do
            </span>
            <span>Regulador Nacional ANAC.</span>
          </p>
          <p>
            <span>
              Nós podemos usar as informações que coletamos quando você se
              registra, responde a uma pesquisa ou comunicação de marketing,
              navega em nosso site, ou utiliza alguns outros recursos da
              plataforma das seguintes maneiras:
            </span>
          </p>
          <p>
            <span>•</span>
            <span>
              &nbsp;Para personalizar a sua experiência e para nos permitir
              entregar o tipo de conteúdo e ofertas de produtos em que você está
              mais interessado.
            </span>
          </p>
          <p>
            <span>•</span>
            <span>
              &nbsp;Para nos permitir melhorar o serviço de respostas às suas
              solicitações de atendimento ao cliente.
            </span>
          </p>
          <p>
            <span>•</span>
            <span>&nbsp;Para processar rapidamente as suas transações.</span>
          </p>
          <p>
            <span>• </span>
            <span>
              Para solicitar classificações e revisões de serviços ou produtos.
            </span>
          </p>
          <p>
            <span>•</span>
            <span>
              &nbsp;Para acompanhá-lo após a correspondência (chat ao vivo,
              e-mail ou ligação telefônica).
            </span>
          </p>

          <h5>
            <p>
              <span>1.1. Dados pessoais fornecidos pelo titular</span>
            </p>
          </h5>
          <p>
            <span>•</span>
            <span>&nbsp;</span>
            <span>Nome completo.</span>
            <span>;</span>
          </p>
          <p>
            <span>•</span>
            <span>&nbsp;</span>
            <span>Data de nascimento</span>
            <span>¹;</span>
          </p>
          <p>
            <span>• </span>
            <span>CPF</span>
            <span>¹</span>
            <span>;</span>
          </p>
          <p>
            <span>•</span>
            <span>&nbsp;</span>
            <span>
              Endereço eletrônico corporativo. Usado no processo de login na
              plataforma.
            </span>
            <span>;</span>
          </p>
          <p>
            <span>•</span>
            <span>&nbsp;</span>
            <span>
              Número de telefone. Usado para habilitar recursos de mobilidade.
            </span>
          </p>
          <p>
            <span>¹ </span>
            <span>
              Opcional para o produto de mobilidade e obrigatório para o produto
              viagens por determinação do Regulador
            </span>
            <span>Nacional</span>
            <span>&nbsp;ANAC.</span>
          </p>

          <h5>
            <p>
              <span>1.2. Dados pessoais coletados automaticamente</span>
            </p>
          </h5>
          <p>
            <span>•</span>
            <span>&nbsp;</span>
            <span>Localização geográfica. </span>
            <span>(Produto Mobilidade)</span>
          </p>
          <p>
            <span>• </span>
            <span>
              Código de identificação (IMEI) do dispositivo móvel através do
              qual o usuário acessou o site e/ou aplicativo.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>I</span>
            <span>
              nformações relativas à data e hora de utilização do site e/ou
              aplicação por um determinado utilizador, a partir de um
              determinado endereço IP.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>
              Informações sobre o número de cliques e tentativas de utilização
              do sites e/ou aplicativos, bem como páginas acessadas pelo
              usuário.
            </span>
          </p>

          <h5>
            <p>
              <span>1.3. Finalidades</span>
            </p>
          </h5>
          <p>
            <span>• </span>
            <span>
              Execução de contrato de trabalhos entre a Tripee e seus Empregados
            </span>
            <span>.</span>
          </p>
          <p>
            <span>• </span>
            <span>
              Execução de contrato entre a Tripee e empresa ou organização em
              que você trabalha.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>
              Exercício legal de direitos da Tripee, inclusive em contratos,
              processos ou cumprimento de obrigações decorrentes de lei
            </span>
            <span>.</span>
          </p>
          <p>
            <span>• </span>
            <span>Melhora a experiência do usuário em nossa plataforma.</span>
          </p>
          <p>
            <span>• </span>
            <span>Cadastro e correta identificação dos usuários.</span>
          </p>
          <p>
            <span>• </span>
            <span>
              Prevenção a fraude, atividades ilícitas, uso indevido ou medidas
              que visem assegurar a segurança dos usuários, parceiros ou
              terceiros.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>
              Atender a requisições de autoridades, conforme legislação.
            </span>
          </p>

          <h5>
            <p>
              <span>2. Como coletamos os seus dados?</span>
            </p>
          </h5>
          <p>
            <span>
              Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte
              forma:
            </span>
          </p>
          <p>
            <span>
              Recebendo o seu cadastro de forma segura através da Empresa que
              você trabalha e também coletamos suas informações quando você se
              registra (ou é registrado) em nosso sistema, faz uma solicitação,
              preenche um formulário ou insere informações em nossos sites e/ou
              aplicativos.
            </span>
          </p>

          <h5>
            <p>
              <span>2.1. Consentimento</span>
            </p>
          </h5>
          <p>
            <span>
              Quando realizarmos algum tratamento com seus dados, que não esteja
              nas finalidades acima descritas
            </span>
            <span>(item 1.3)</span>
            <span>
              , como por exemplo uma ação de marketing diferenciada, iremos
              solicitar seu consentimento.
            </span>
          </p>
          <p>
            <span>
              É a partir do seu consentimento que tratamos os seus dados
              pessoais. O consentimento é a manifestação livre, informada e
              inequívoca pela qual você autoriza a
            </span>
            <span>Tripee</span>
            <span>&nbsp;a tratar seus dados.</span>
          </p>
          <p>
            <span>
              Assim, em consonância com a Lei Geral de Proteção de Dados, seus
              dados só serão coletados, tratados e armazenados mediante prévio e
              expresso consentimento.
            </span>
          </p>
          <p>
            <span>
              O seu consentimento será obtido de forma específica para cada
              finalidade &nbsp;que não se enquadrar nas acima descritas
            </span>
            <span>(item 1.3)</span>
            <span>
              , evidenciando o compromisso de transparência e boa-fé da
            </span>
            <span>Tripee</span>
            <span>
              &nbsp;para com seus usuários/clientes, seguindo as regulações
              legislativas pertinentes.
            </span>
          </p>
          <p>
            <span>Ao utilizar os serviços da </span>
            <span>Tripee</span>
            <span>
              &nbsp;e fornecer seus dados pessoais, você está ciente e
              consentindo com as disposições desta Política de Privacidade, além
              de conhecer seus direitos e como exercê-los.
            </span>
          </p>
          <p>
            <span>
              A qualquer tempo e sem nenhum custo, você poderá revogar seu
              consentimento.É importante destacar que a revogação do
              consentimento para o tratamento dos dados pode implicar a
              impossibilidade da performance adequada de alguma funcionalidade
              da plataforma
            </span>
            <span>&nbsp;</span>
            <span>
              que dependa da operação. Tais consequências serão informadas
              previamente.;
            </span>
          </p>

          <h5>
            <p>
              <span>3. Quais são os seus direitos?</span>
            </p>
          </h5>
          <p>
            A Tripee assegura a seus usuários/clientes seus direitos de titular
            previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa
            forma, você pode, de maneira gratuita e a qualquer tempo:
          </p>
          <p>
            <span>• </span>
            <span>Confirmar a existência de tratamento de dados</span>
            <span>
              , de maneira simplificada ou em formato claro e completo.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>Acessar seus dados</span>
            <span>
              , podendo solicitá-los em uma cópia legível sob forma impressa ou
              por meio eletrônico, seguro e idôneo.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>Corrigir seus dados</span>
            <span>
              , ao solicitar a edição, correção ou atualização destes.
            </span>
          </p>
          <p>
            <span>•</span>
            <span>&nbsp;Limitar seus dados</span>
            <span>
              &nbsp;quando desnecessários, excessivos ou tratados em
              desconformidade com a legislação através da anonimização, bloqueio
              ou eliminação.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>Solicitar a portabilidade de seus dados</span>
            <span>, através de um relatório de dados cadastrais que a</span>
            <span>Tripee</span>
            <span>&nbsp;trata a seu respeito.</span>
          </p>
          <p>
            <span>• </span>
            <span>Eliminar seus dados</span>
            <span>
              &nbsp;tratados a partir de seu consentimento, exceto nos casos
              previstos em lei.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>Revogar seu consentimento</span>
            <span>, desautorizando o tratamento de seus dados.</span>
          </p>
          <p>
            <span>• </span>
            <span>
              Informar-se sobre a possibilidade de não fornecer seu
              consentimento
            </span>
            <span>&nbsp;e sobre as consequências da negativa.;</span>
          </p>

          <h5>
            <p>
              <span>4. Como você pode exercer seus direitos de titular?</span>
            </p>
          </h5>
          <p>
            <span>
              Para exercer seus direitos de titular, você deve entrar em contato
              com a
            </span>
            <span>Tripee</span>
            <span>&nbsp;através dos seguintes meios disponíveis:</span>
          </p>
          <p>
            <span>E-mail: suporte@tripee.to</span>
          </p>
          <p>
            <span>
              De forma a garantir a sua correta identificação como titular dos
              dados pessoais objeto da solicitação, é possível que solicitemos
              documentos ou demais comprovações que possam comprovar sua
              identidade. Nessa hipótese, você será informado previamente.
            </span>
          </p>

          <h5>
            <p>
              <span>
                5. Como e por quanto tempo seus dados serão armazenados?
              </span>
            </p>
          </h5>
          <p>
            <span>Seus dados pessoais coletados pela </span>
            <span>Tripee</span>
            <span>
              &nbsp;serão utilizados e armazenados durante o tempo necessário
              para a prestação do serviço, ou até que as finalidades elencadas
              na presente Política de Privacidade sejam atingidas, considerando
              os direitos dos titulares dos dados e dos controladores.
            </span>
          </p>
          <p>
            <span>
              De modo geral, seus dados serão mantidos enquanto a relação
              contratual entre a sua empresa e a
            </span>
            <span>Tripee</span>
            <span>
              &nbsp;perdurar, ou mediante solicitação da sua empresa. Findado o
              contrato de sua empresa com a Tripee, seus dados serão excluídos
              no prazo máximo de 90 dias ou serão anonimizados, ressalvadas as
              hipóteses legalmente previstas no artigo 16 da lei geral de
              proteção de dados, a saber:
            </span>
          </p>
          <p>
            <span>
              I – cumprimento de obrigação legal ou regulatória pelo
              controlador;
            </span>
          </p>
          <p>
            <span>
              II – transferência a terceiro, desde que respeitados os requisitos
              de tratamento de dados dispostos nesta Lei;
            </span>
          </p>
          <p>
            <span>
              III – uso exclusivo do controlador, vedado seu acesso por
              terceiro, e desde que anonimizados os dados.
            </span>
          </p>
          <p>
            <span>
              Isto é, informações pessoais sobre você que sejam imprescindíveis
              para o cumprimento de determinações legais, judiciais e
              administrativas e/ou para o exercício do direito de defesa em
              processos judiciais e administrativos serão mantidas, a despeito
              da exclusão dos demais dados.
            </span>
          </p>
          <p>
            <span>
              Os titulares de dados podem solicitar a exclusão de seus dados a
              qualquer momento. A Tripee pode reter os dados de um usuário mesmo
              após a solicitação de exclusão, devido a exigências legais,
              regulatórias ou pelos motivos declarados nesta política.
            </span>
            <span>
              Isso significa que são retidos dados de diferentes categorias por
              períodos distintos, dependendo do tipo dos dados, do tipo do
              titular dos dados a quem os dados se referem e da finalidade da
              coleta.
            </span>
          </p>
          <p>
            <span>O armazenamento dos dados coletados pela </span>
            <span>Tripee</span>
            <span>
              &nbsp;reflete o nosso compromisso com a segurança e privacidade
              dos seus dados. São empregadas medidas e soluções técnicas de
              proteção, aptas a garantir a confidencialidade, integridade e
              inviolabilidade dos seus dados. Além disso, também são aplicadas
              medidas de segurança apropriadas aos riscos e com controle de
              acesso às informações armazenadas.
            </span>
          </p>

          <h5>
            <p>
              <span>6. O que fazemos para manter seus dados seguros?</span>
            </p>
          </h5>
          <p>
            <span>
              Para mantermos suas informações pessoais seguras, usamos
              ferramentas físicas, eletrônicas e gerenciais orientadas para a
              proteção da sua privacidade.
            </span>
          </p>
          <p>
            <span>
              Nosso sistema é verificado em uma base regular para falhas de
              segurança e vulnerabilidades conhecidas para tornar o seu acesso
              tão seguro quanto possível.
            </span>
          </p>
          <p>
            <span>
              Aplicamos essas ferramentas levando em consideração a natureza dos
              dados pessoais coletados, o contexto e a finalidade do tratamento
              e os riscos que eventuais violações possam gerar para os direitos
              e liberdades do titular dos dados coletados e tratados.
            </span>
          </p>
          <p>
            <span>Entre as medidas que adotamos, destacamos as seguintes:</span>
          </p>
          <p>
            <span>• </span>
            <span>
              Apenas pessoas autorizadas têm acesso a seus dados pessoais.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>
              O acesso a seus dados pessoais é feito somente após o compromisso
              de confidencialidade, e todas as ações são auditadas.
            </span>
          </p>
          <p>
            <span>• </span>
            <span>
              Seus dados pessoais são armazenados em ambiente seguro ,
              criptografado e idôneo.
            </span>
          </p>
          <p>
            <span>
              A Tripee se compromete a adotar as melhores posturas para evitar
              incidentes de segurança. Contudo, é necessário destacar que nenhum
              recurso tecnológico é inteiramente seguro e livre de riscos.
            </span>
            <span>
              É possível que, apesar de todos os nossos protocolos de segurança,
              problemas de culpa exclusivamente de terceiros ocorram, como
              ataques cibernéticos de
            </span>
            <span>hackers</span>
            <span>
              , ou também em decorrência da negligência ou imprudência do
              próprio usuário/cliente.
            </span>
          </p>
          <p>
            <span>
              Em caso de incidentes de segurança que possam gerar risco ou dano
              relevante para você ou qualquer um de nossos usuários/clientes,
              comunicaremos aos afetados e a Autoridade Nacional de Proteção de
              Dados sobre o ocorrido, em consonância com as disposições da Lei
              Geral de Proteção de Dados.;
            </span>
          </p>

          <h5>
            <p>
              <span>7. Com quem seus dados podem ser compartilhados?</span>
            </p>
          </h5>
          <p>
            <span>
              Tendo em vista a preservação de sua privacidade, a Tripee não
              compartilhará seus dados pessoais com nenhum terceiro não
              autorizado. Somente com seus parceiros comerciais, e por questões
              de estratégia de negócio, não compartilharemos quais são nesta
              política. Tais dados, poderão ser requisitados via e-mail ao nosso
              DPO.
            </span>
          </p>
          <p>
            <span>
              Estes recebem seus dados apenas na medida do necessário para a
              prestação dos serviços contratados e nossos contratos são
              orientados pelas normas de proteção de dados do ordenamento
              jurídico brasileiro.
            </span>
          </p>
          <p>
            <span>
              Todavia, nossos parceiros têm suas próprias Políticas de
              Privacidade, que podem divergir desta. Recomendamos a leitura
              desses documentos, em seus respectivos sites.
            </span>
          </p>
          <p>
            <span>
              Além disso, também existem outras hipóteses em que seus dados
              poderão ser compartilhados, que são:
            </span>
          </p>
          <p>
            <span>
              I – Determinação legal, requerimento, requisição ou ordem
              judicial, com autoridades judiciais, administrativas ou
              governamentais competentes.
            </span>
          </p>
          <p>
            <span>
              II – Caso de movimentações societárias, como fusão, aquisição e
              incorporação, de forma automática.
            </span>
          </p>
          <p>
            <span>III – Proteção dos direitos da </span>
            <span>Tripee</span>
            <span>
              &nbsp;em qualquer tipo de conflito, inclusive os de teor judicial.
            </span>
          </p>
          <p>
            <span>
              IV – Quando o tratamento compartilhado de dados for necessário
              para a execução de políticas públicas.
            </span>
          </p>
          <p>
            <span>
              V – Para que os órgãos de pesquisa possam realizar estudos, sempre
              observando a anonimização de dados pessoais sensíveis.
            </span>
          </p>
          <p>
            <span>
              VI – Em caso de proteção da vida ou segurança física do titular
              dos dados, ou de terceiros.
            </span>
          </p>
          <p>
            <span>
              VII – Para tutela de saúde, em procedimentos que devem ser
              realizados por profissionais ou serviços de saúde/autoridade
              sanitária.
            </span>
          </p>
          <p>
            <span>
              VIII – Para garantir que o titular dos dados esteja seguro e
              prevenido de fraudes, sempre observando o direito à informação e
              transparência garantido pela lei (exceto em casos onde a proteção
              dos dados seja fundamental para garantir direitos e liberdades).
            </span>
          </p>
          <p>
            <span>
              IX – Para a proteção do crédito, inclusive quanto ao disposto na
              legislação pertinente.
            </span>
          </p>

          <h5>
            <p>
              <span>7.1. Transferência internacional de dados</span>
            </p>
          </h5>
          <p>
            <span>
              Alguns dos terceiros com quem compartilhamos seus dados podem ser
              localizados ou ou possuir instalações localizadas em países
              estrangeiros. Nessas condições, de toda forma, seus dados pessoais
              estarão sujeitos à Lei Geral de Proteção de Dados e às demais
              legislações brasileiras de proteção de dados.
            </span>
          </p>
          <p>
            <span>
              Nesse sentido, a Tripee se compromete a sempre adotar eficientes
              padrões de segurança cibernética e de proteção de dados, nos
              melhores esforços de garantir e cumprir as exigências
              legislativas.
            </span>
          </p>
          <p>
            <span>
              Ao concordar com essa Política de Privacidade, você concorda com
              esse compartilhamento, que se dará conforme as finalidades
              descritas no presente instrumento.
            </span>
          </p>

          <h5>
            <p>
              <span>8. Cookies ou dados de navegação</span>
            </p>
          </h5>
          <p>
            <span>
              A Tripee faz uso de Cookies, que são arquivos de texto enviados
              pela plataforma ao seu computador e que nele se armazenam, que
              contém informações relacionadas à navegação do site. Em suma, os
              Cookies são utilizados para aprimorar a experiência de uso.
            </span>
          </p>
          <p>
            <span>
              Ao acessar nosso site e consentir com o uso de Cookies, você
              manifesta conhecer e aceitar a utilização de um sistema de coleta
              de dados de navegação com o uso de Cookies em seu dispositivo.
            </span>
          </p>
          <p>
            <span>
              Você pode, a qualquer tempo e sem nenhum custo, alterar as
              permissões, bloquear ou recusar os Cookies. Todavia, a revogação
              do consentimento de determinados Cookies pode inviabilizar o
              funcionamento correto de alguns recursos da plataforma.
            </span>
          </p>
          <p>
            <span>
              Para gerenciar os cookies do seu navegador, basta fazê-lo
              diretamente nas configurações do navegador, na área de gestão de
              Cookies. Você pode acessar tutoriais sobre o tema diretamente nos
              links abaixo:
            </span>
          </p>
          <p>
            <span>Internet Explorer / Edge:</span>
            <span>&nbsp;</span>
            <span>
              https://docs.microsoft.com/pt-br/microsoft-edge/privacy-whitepaper
            </span>
          </p>
          <p>
            <span>Firefox:</span>
            <span>&nbsp;</span>
            <span>
              https://support.mozilla.org/en-US/products/firefox/protect-your-privacy
            </span>
          </p>
          <p>
            <span>Safari:</span>
            <span>&nbsp;</span>
            <span>
              https://support.apple.com/pt-br/guide/safari/sfri11471/mac
            </span>
          </p>
          <p>
            <span>Google Chrome:</span>
            <span>&nbsp;</span>
            <span>
              https://policies.google.com/technologies/cookies?hl=en-US
            </span>
          </p>
          <p>
            <span>Opera:</span>
            <span>&nbsp;</span>
            <span>https://www.opera.com/pt-br/privacy/cookies</span>
          </p>

          <h5>
            <p>
              <span>9. Alteração desta Política de Privacidade</span>
            </p>
          </h5>
          <p>
            <span>
              A atual versão da Política de Privacidade foi formulada e
              atualizada pela última vez em: 02/02/2024
            </span>
          </p>
          <p>
            <span>
              Reservamos o direito de modificar essa Política de Privacidade a
              qualquer tempo, principalmente em função da adequação a eventuais
              alterações feitas em nosso site ou em âmbito legislativo.
              Recomendamos que você a revise com frequência.
            </span>
          </p>
          <p>
            <span>
              Eventuais alterações entrarão em vigor a partir de sua publicação
              em nosso site e sempre lhe notificaremos acerca das mudanças
              ocorridas.
            </span>
          </p>
          <p>
            <span>
              Ao utilizar nossos serviços e fornecer seus dados pessoais após
              tais modificações, você às consente.
            </span>
          </p>

          <h5>
            <p>
              <span>10. Responsabilidade</span>
            </p>
          </h5>
          <p>
            <span>
              A Tripee prevê a responsabilidade dos agentes que atuam nos
              processos de tratamento de dados, em conformidade com os artigos
              42 ao 45 da Lei Geral de Proteção de Dados.
            </span>
          </p>
          <p>
            <span>
              Nos comprometemos em manter esta Política de Privacidade
              atualizada, observando suas disposições e zelando por seu
              cumprimento.
            </span>
          </p>
          <p>
            <span>
              Além disso, também assumimos o compromisso de buscar condições
              técnicas e organizativas seguramente aptas a proteger todo o
              processo de tratamento de dados.
            </span>
          </p>
          <p>
            <span>
              Caso a Autoridade Nacional de Proteção de Dados exija a adoção de
              providências em relação ao tratamento de dados realizado pela
            </span>
            <span>Tripee</span>
            <span>, </span>
            <span>comprometemo-nos a segui-las.</span>
          </p>
          <h5>
            <p>
              <span>10.1 Isenção de responsabilidade</span>
            </p>
          </h5>
          <p>
            <span>
              Conforme mencionado no Tópico 6, embora adotemos elevados padrões
              de segurança a fim de evitar incidentes, não há nenhuma página
              virtual inteiramente livre de riscos. Nesse sentido, a Tripee não
              se responsabiliza por:
            </span>
          </p>
          <p>
            <span>
              I – Quaisquer consequências decorrentes da negligência,
              imprudência ou imperícia dos usuários em relação a seus dados
              individuais. Garantimos e nos responsabilizamos apenas pela
              segurança dos processos de tratamento de dados e do cumprimento
              das finalidades descritas no presente instrumento.
            </span>
          </p>
          <p>
            <span>
              Destacamos que a responsabilidade em relação à confidencialidade
              dos dados de acesso é do usuário.
            </span>
          </p>
          <p>
            <span>II – Ações maliciosas de terceiros, como ataques de</span>
            <span>hackers</span>
            <span>, exceto se comprovada conduta culposa ou deliberada da</span>
            <span>Tripee</span>
            <span>.</span>
          </p>
          <p>
            <span>
              Destacamos que em caso de incidentes de segurança que possam gerar
              risco ou dano relevante para você ou qualquer um de nossos
              usuários/clientes, comunicaremos aos afetados e à Autoridade
              Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as
              providências necessárias.
            </span>
          </p>
          <p>
            <span>
              III – Inveracidade das informações inseridas pelo usuário/cliente
              nos registros necessários para a utilização dos serviços da
            </span>
            <span>Tripee</span>
            <span>
              ; quaisquer consequências decorrentes de informações falsas ou
              inseridas de má-fé são de inteiramente responsabilidade do
              usuário/cliente.
            </span>
          </p>

          <h5>
            <p>
              <span>11. Encarregado de Proteção de Dados</span>
            </p>
          </h5>
          <p>
            <span>
              A Tripee disponibiliza os seguintes meios para que você possa
              entrar em contato conosco para exercer seus direitos de titular:
            </span>
          </p>
          <p>
            <span>
              Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os
              dados pessoais que tratamos, você pode entrar em contato com o
              nosso Encarregado de Proteção de Dados Pessoais, através dos
              seguintes canais:
            </span>
          </p>
          <p>
            <span>Email do </span>
            <span>Encarregado</span>
            <span>:</span>
            <span>
              <a href='mailto:suporte@tripee.to'>suporte@tripee.to</a>
            </span>
          </p>
        </div>
      </div>
    );
  }
}
