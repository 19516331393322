import React, { Component } from 'react'
import Logo from '../../images/logo_auto_invite.png'
import './auto-convite.css'
import api from '../../services/api'

export default class AutoInvite extends Component {
    constructor(props) {
        super(props);
        ////console.log(this.props)
        this.state = {
            email: '',
            email_corp: '',
            success: false,
            err: false
        };
    }
    handleSubmit = async (e) => {
        e.preventDefault()
        var res
        try {
            res = await api.post(`/login/reset-pwd`, { email: this.state.email, email_corp: this.state.email_corp })
        } catch (error) {
            this.setState({ err: true })
        }
        if (res.status === 200) {
            this.setState({ success: true })
        }
        else {
            this.setState({ err: true })
        }
    }
    render() {
        return (
            <div className="container col-md-5 mt-3 mb-3">
                <div className="box-shadow">
                    <div className="text-center mb-4">
                        <img
                            className="mb-4"
                            src={Logo}
                            alt="Tripee"
                            width="180"
                            height="100"
                        />
                        {this.state.err === true ? (<div className="alert alert-danger" id="danger-alert">
                            <button type="button" className="close" data-dismiss="alert">
                                X
                            </button>
                            Desculpe não foi possível completar sua solicitação. Por favor entrar em contato com o suporte
                        </div>) : ("")
                        }
                        {this.state.success === true ? (
                            <div className="alert alert-success" id="danger-alert">
                                <button type="button" className="close" data-dismiss="alert">
                                    X
                            </button>
                            Convite enviado com sucesso, verifique sua caixa de entrada
                            </div>) : ("")
                        }
                        <div className="text-area">
                            <h1 className="h3 mb-3 font-weight-normal">Solicitar convite</h1>
                            <p>
                                Por favor informe sua funcional e email corporativo para receber o
                                email de acesso ao app Tripee
                            </p>
                        </div>
                    </div>
                    <div className="container">
                        <form className="" id="aif" onSubmit={e => this.handleSubmit(e)}>
                            <div className="form-label-group">
                                <h6 className="label">Funcional.</h6>
                                <input
                                    type="text"
                                    id="ai_name"
                                    className="input"
                                    placeholder="Funcional"
                                    name="username"
                                    required
                                    autoFocus
                                    onChange={e => { this.setState({ email: e.target.value }) }}
                                />
                            </div>
                            <div className="form-label-group mt-3">
                                <h6 className="label">Email corporativo.</h6>
                                <input
                                    type="email"
                                    id="ai_email"
                                    className="input"
                                    placeholder="Email Corporativo"
                                    name="email"
                                    required
                                    onChange={e => { this.setState({ email_corp: e.target.value }) }}
                                />
                            </div>
                            <button className="button" type="submit">
                                Enviar o convite
                    </button>
                            <p className="mt-5 mb-3 text-muted text-center">
                                &copy; Tripee LLC 2020
                    </p>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}