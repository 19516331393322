import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import "./i18n";


import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap/dist/css/bootstrap.min.css";

import './index.css';
import './global.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
