import React, { Suspense } from "react";
import { Translation } from "react-i18next";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export default function ({ translationKey }) {
    return (
        <Suspense fallback={<Skeleton count={1} />}>
           <Translation>
                {t =>  t(translationKey)}
            </Translation>
        </Suspense>
    )
}