import './App.css';
import React from 'react';
import { BrowserRouter } from "react-router-dom";

import Routes from "./routes";



function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <div >
                    <Routes />
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
